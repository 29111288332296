import * as React from "react"

import Layout from "../components/layout"
import Blog from "../components/blog"
import Seo from "../components/seo"

const seo = {
  metaTitle: 'Blog',
}

const BlogPage = () => (
  <Layout active="blog" page="blog">
    <Seo seo={seo} />
    <Blog items={999} />
  </Layout>
)

export default BlogPage
